section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

@keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(50px); /* Move 50px down */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Move back to original position */
    }
  }
  
  .fade-in {
    animation: fade-in 0.5s ease forwards; /* Adjust timing as needed */
  }
  