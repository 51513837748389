.sign-in-container {
    display: flex;
    flex-direction: column;
    width: 380px;
  
    h2 {
      margin: 10px 0;
    }
  
    .buttons-container {
      display: flex;
      justify-content: center;
    }
  }

